import store from './store'
import { variables, generate } from '@/libs/theme'
import _ from 'lodash'
import { treeMap } from 'operation-tree-node'
import moment, { Moment } from 'moment'

const color = [
  '#5470c6',
  '#91cc75',
  '#fac858',
  '#ee6666',
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#9a60b4',
  '#ea7ccc'
]

const mergeOption = (option: Record<string, any>) =>
  _.merge(
    {
      color,
      textStyle: { color: variables.textColorSecondary },
      tooltip: {
        borderWidth: 0,
        backgroundColor: variables.backgroundColorPopper,
        textStyle: { fontSize: 14, color: variables.textColorRegular },
        axisPointer: { type: 'cross' }
      }
    },
    option
  )

const mergeLineOption = (option: Record<string, any>) =>
  _.merge(
    mergeOption({
      title: {
        show: true,
        bottom: 10,
        left: '50%',
        right: '50%',
        textAlign: 'center',
        textStyle: {
          color: variables.textColorRegular,
          fontWeight: 'normal',
          fontSize: 14
        }
      },
      tooltip: {
        trigger: 'axis',
        formatter: (data: AnyObj[]) => {
          if (data.length > 0) {
            const { axisValue } = data[0]
            const date = moment(axisValue).format(
              store.span === 'day' ? 'YYYY-MM-DD' : 'MM-DD HH:mm'
            )

            return `<div>
              <div>
                <span style="line-height: 28px">${date}</span>
              <div>

              ${data
                .map(
                  ({ seriesName, value }) => `<div>
                  <span>${seriesName}：</span> <span style="color: ${variables.colorPrimaryLight}">${value}</span>
                </div>`
                )
                .join('')}
            </div>`
          }
        }
      },
      legend: {
        icon: 'rect',
        top: 10,
        right: 10,
        itemWidth: 8,
        itemHeight: 8,
        itemGap: 32,
        textStyle: { color: variables.textColorRegular }
      },
      xAxis: {
        type: 'category',
        nameTextStyle: { color: variables.textColorSecondary },
        axisLine: { show: false },
        axisPointer: { label: { show: false } },
        axisLabel: {
          formatter: (value: Moment) =>
            moment(value).format(store.span === 'day' ? 'MM-DD' : 'HH:mm')
        },
        data: store.dateArray.map(date => date.toISOString())
      },
      yAxis: {
        splitLine: { lineStyle: { type: 'dotted', color: '#46565C' } },
        axisLine: { show: false },
        type: 'value'
      }
    }),
    option
  )

const line = {
  totals: () =>
    mergeLineOption({
      title: { text: `共${store.total}人次` },
      series: [{ name: '人次', data: store.totals, type: 'line' }]
    }),
  types: () =>
    mergeLineOption({
      title: { text: `共${store.totalOfTypes}人次` },
      series: store.totalsOfTypes.map(({ name, data }) => ({ name, data, type: 'line' }))
    }),
  tags: () => {
    return mergeLineOption({
      title: { text: `共${store.totalOfTags}人次` },
      series: store.totalsOfTags.map(({ name, data }) => ({ name, data, type: 'line' }))
    })
  },
  keywords: () =>
    mergeLineOption({
      title: { text: `共${store.totalOfKeywords}人次` },
      series: store.totalsOfKeywords.map(({ name, data }) => ({ name, data, type: 'line' }))
    })
}

const subName = (obj: any) => {
  obj.tipName = obj.name
  let str = ''
  const maxLength = 5
  const valLength = obj.name.length
  if (valLength > maxLength) {
    const rowNumber = Math.ceil(valLength / maxLength)
    for (let i = 0; i < rowNumber; i++) {
      let temp = ''
      const start = i * maxLength
      const end = start + maxLength
      temp = obj.name.substring(start, end) + '\n'
      str += temp
    }
    obj.name = str
  }
  return obj
}

const sunburst = () => {
  const root = store.dataOfAreas[0]
  let count = 3
  let colorList: any[] = []
  let flag = false
  const data = treeMap(store.dataOfAreas, (node, index, _, parent) => {
    node = subName(node)
    if (node?.id === root?.id) {
      return {
        ...node,
        itemStyle: { color: variables.colorPrimary }
      }
    } else if (parent?.id === root?.id) {
      count = 3
      colorList = generate(color[index % color.length], {
        theme: 'dark'
      }).reverse()
      return { ...node, itemStyle: { color: colorList[count] } }
    } else {
      if (flag || count === 9) count = 3
      count++
      flag = node.children ? false : true
      return { ...node, itemStyle: { color: colorList[count] } }
    }
  })

  return mergeOption({
    tooltip: {
      backgroundColor: variables.backgroundColorPopper,
      formatter: ({ value, treePathInfo, data }: AnyObj) => {
        const current = treePathInfo[treePathInfo.length - 1]
        const parent = treePathInfo[treePathInfo.length - 2]

        if (data.tipName) {
          const round = (num: number, n: number) =>
            Math.round(num * Math.pow(10, n)) / Math.pow(10, n)
          const proportion = round((current.value / parent.value) * 100, 2)

          return `${data.tipName} <br/> 人次 ${value} <br/> 比例 ${proportion}%`
        }
      }
    },
    textStyle: {
      width: 4,
      overflow: 'break'
    },
    series: {
      type: 'sunburst',
      radius: ['10%', '90%'],
      center: ['50%', '50%'],
      highlightPolicy: 'self',
      label: {
        rotate: 'radial',
        align: 'center',
        fontSize: 12,
        fontWeight: 'normal',
        color: [variables.whiteColor],
        minAngle: 8
      },
      itemStyle: {
        borderColor: variables.backgroundColorBase,
        borderWidth: 2
      },
      data,
      levels: [
        {
          tooltip: { show: false },
          itemStyle: {
            color: variables.backgroundColorBase
          }
        },
        {
          itemStyle: {
            color: variables.colorPrimary
          }
        }
      ]
    }
  })
}

const pie = () =>
  mergeOption({
    tooltip: {
      trigger: 'item',
      formatter: '{b} <br/> 人次 {c} <br/> 比例 {d}%'
    },
    legend: {
      icon: 'rect',
      orient: 'vertical',
      bottom: 30,
      right: 20,
      itemWidth: 8,
      itemHeight: 8,
      itemGap: 12,
      textStyle: { color: variables.textColorRegular }
    },
    series: [
      {
        name: '访问来源',
        type: 'pie',
        radius: ['26%', '72%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderColor: variables.backgroundColorBase,
          borderWidth: 2
        },
        label: {
          position: 'inside',
          color: variables.textColorRegular,
          formatter: ({ percent, name }: AnyObj) => (percent > 4 ? name : '')
        },
        data: store.dataOfTypes
      }
    ]
  })

const renderer = (getOption: () => Record<string, any>) => (el: HTMLDivElement) => {
  if (el) {
    const chart = echarts.init(el)

    chart.clear()
    chart.setOption(getOption())
  }
}

const resize = (el: HTMLDivElement) => {
  if (el) {
    echarts.init(el).resize()
  }
}

const dispose = (el: HTMLDivElement) => {
  if (el) {
    echarts.init(el).dispose()
  }
}

const charts = {
  line: _.mapValues(line, option => renderer(option)),
  sunburst: renderer(sunburst),
  pie: renderer(pie),
  resize,
  dispose
}

export default charts
