import moment, { Moment } from 'moment'
import { treeFilter, treeMap } from 'operation-tree-node'

type DataItem = {
  areaId: string
  date: string
  tagId: string
  tagType: string
  total: string
  type: number
}

type Keyword = 'landlord' | 'relative' | 'renter' | 'pupil' | 'visitor'

type Tag = {
  id: string
  name: string
  keyword: Keyword | null
}

type Area = {
  id: string
  name: string
  children: Area[]
}

const types = [
  { name: '人脸', value: 0 },
  { name: '身份证', value: 1 },
  { name: '刷卡', value: 2 },
  { name: '扫码', value: 3 },
  { name: '未注册身份证', value: 4 },
  { name: '未注册卡', value: 5 },
  // { name: '人证核验', value: 6 },
  { name: '其他', value: 7 }
]

const keywords: { keyword: Keyword; name: string }[] = [
  { keyword: 'landlord', name: '户主' },
  { keyword: 'relative', name: '亲属' },
  { keyword: 'renter', name: '租客' },
  { keyword: 'visitor', name: '访客' },
  { keyword: 'pupil', name: '被监护人' }
]

const strangeId = 'strange'

class Store {
  data: DataItem[] = []
  dateRange: string[] = []
  tagList: Tag[] = []
  selectedTagList: Tag[] = []
  areaList: Area[] = []

  get span(): 'hour' | 'day' {
    const [start, end] = this.dateRange

    if (moment(start).add(3, 'day').isAfter(end)) {
      return 'hour'
    }

    return 'day'
  }

  get dateArray() {
    const [startTime, endTime] = this.dateRange
    const dateArray: Moment[] = []
    const date = moment(startTime)

    while (date.isSameOrBefore(endTime)) {
      dateArray.push(moment(date))

      if (this.span === 'day') {
        date.add(1, 'day')
      } else {
        date.add(1, 'hour')
      }
    }

    return dateArray
  }

  get total() {
    return this.getTotal(this.data)
  }

  get totalOfTypes() {
    return this.getTotal(this.data.filter(({ type }) => types.find(({ value }) => type === value)))
  }

  get totalOfTags() {
    return this.getTotal(
      this.data.filter(({ tagId }) => this.selectedTagList.find(({ id }) => id === tagId))
    )
  }

  get totalOfKeywords() {
    const data = keywords.map(i => ({ ...i, ids: [] as string[] }))

    this.tagList.forEach(({ id, keyword }) => {
      data.find(i => i.keyword === keyword)?.ids.push(id)
    })

    const total = this.getTotal(
      this.data.filter(({ tagId }) => data.find(({ ids }) => ids.includes(tagId)))
    )

    return total + this.totalOfStrange
  }

  get totalOfStrange() {
    return this.getTotal(this.data.filter(({ tagId }) => tagId === strangeId))
  }

  get totals() {
    return this.getTotals(this.data)
  }

  get totalsOfTypes() {
    return types.map(({ name, value }) => ({
      name,
      data: this.getTotals(this.data.filter(({ type }) => type === value))
    }))
  }

  get totalsOfTags() {
    return this.selectedTagList.map(({ name, id }) => ({
      name,
      data: this.getTotals(this.data.filter(({ tagId }) => tagId === id))
    }))
  }

  get totalsOfKeywords() {
    const data = keywords.map(i => ({ ...i, ids: [] as string[] }))

    this.tagList.forEach(({ id, keyword }) => {
      data.find(i => i.keyword === keyword)?.ids.push(id)
    })

    const totals = data.map(({ ids, name }) => ({
      name,
      data: this.getTotals(this.data.filter(({ tagId }) => ids.includes(tagId)))
    }))

    return [...totals, this.totalsOfStrange]
  }

  get totalsOfStrange() {
    return {
      name: '陌生人',
      data: this.getTotals(this.data.filter(({ tagId }) => tagId === strangeId))
    }
  }

  get dataOfAreas() {
    return treeFilter(
      treeMap(this.areaList, ({ id, name, children }) => {
        if (children) {
          return { id, name, children }
        } else {
          return {
            id,
            name,
            value: this.data
              .filter(({ areaId }) => areaId === id)
              .reduce((a, b) => a + parseInt(b.total), 0)
          }
        }
      }),
      ({ value }) => !!value
    )
  }

  get dataOfTypes() {
    return types.map(({ name, value }) => ({
      name,
      value: this.data
        .filter(({ type }) => type === value)
        .reduce((prev, curr) => prev + parseInt(curr.total), 0)
    }))
  }

  private getTotal(data: DataItem[]) {
    return data.reduce<number>((acc, { total }) => acc + parseInt(total), 0)
  }

  private getTotals(data: DataItem[]) {
    const map: Record<string, number> = {}

    data.forEach(({ date, total }) => {
      const key = moment(date).toISOString()
      if (!map[key]) map[key] = 0
      map[key] += parseInt(total)
    })

    return this.dateArray.map(date => map[date.toISOString()] ?? 0)
  }
}

export default new Store()
