



































































































































































import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator'
import PageTitle from '@/components/common/pageTitle.vue'
import store from './store'
import charts from './charts'
import { Moment } from 'moment'
import { treeEach } from 'operation-tree-node'
import { is, record, round, identityMap } from '@/utils/helpers'
import { actionTypes } from '@/store'

@Component({ components: { PageTitle } })
export default class PassingStatistic extends Vue {
  /**
   * 标签图表是否使用固定的包含 keywords 字段的标签（户主、亲属、租客、访客、被监护人）
   * 若选项为 true，则页面筛选及标签图表不支持自定义标签筛选
   */
  @Prop({ default: false }) useKeywordTags: boolean

  @Ref('lineChart') private readonly lineChart: HTMLDivElement
  @Ref('sunburstChart') private readonly sunburstChart: HTMLDivElement
  @Ref('pieChart') private readonly pieChart: HTMLDivElement

  private filterData: AnyObj = {
    dateRange: [this.$moment().startOf('day'), this.$moment().endOf('day')]
  }
  private tabValue: 'totals' | 'types' | 'tags' | 'keywords' = 'totals'
  private totals: { total: number; date: string }[] = []
  private store = store
  private filteredTagList: any[] = []
  private selectedTagIds: string[] = []
  private loading = false

  get areas(): any[] {
    return this.$store.state.baseAreas
  }

  get tags(): any[] {
    return this.$store.state.tags
  }

  get rootAreaId() {
    return this.$store.state.areaId
  }

  get noData() {
    return this.store.data.length === 0
  }

  get today() {
    return this.$moment().startOf('day')
  }

  get todayTotal() {
    return this.getTotal(this.$moment(this.today))
  }

  get yesterdayTotal() {
    return this.getTotal(this.$moment(this.today).subtract(1, 'day'))
  }

  get weeklyTotal() {
    return this.getTotalInRange(this.$moment(this.today), 7)
  }

  get monthlyTotal() {
    return this.getTotalInRange(this.$moment(this.today), 30)
  }

  get isTagsTab() {
    return this.tabValue === 'tags'
  }

  get defaultAreaIds() {
    const areaIds: string[] = []
    treeEach(this.areas, ({ id, children }) => {
      if (!children) {
        areaIds.push(id)
      }
    })

    return areaIds
  }

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get params() {
    const {
      areaIds,
      identity,
      dateRange: [startTime, endTime]
    } = this.filterData

    return record({ areaIds, identity, startTime, endTime }).filter(is.ava)
  }

  get chartEls() {
    const els: HTMLDivElement[] = []

    for (const key in this.$refs) {
      const el = this.$refs[key]
      if (el instanceof HTMLDivElement) {
        els.push(el)
      }
    }

    return els
  }

  @Watch('tabValue')
  watchTabValue() {
    charts.line[this.tabValue](this.lineChart)
  }

  async mounted() {
    this.loading = true
    await this.fetchCounts()
    await Promise.all([this.fetchTags(), this.fetchAreas()])
    this.loading = false

    this.fetchStatistics()
    this.observeResize()
  }

  observeResize() {
    let timer = 0

    addEventListener('resize', () => {
      if (timer) {
        clearTimeout(timer)
      }

      timer = window.setTimeout(() => {
        this.resizeCharts()
      }, 100)
    })
  }

  async fetchCounts() {
    const endTime = this.$moment().endOf('day').toISOString()
    const startTime = this.$moment().subtract('60', 'day').startOf('day').toISOString()

    const { data } = await this.$api.base.getPassageCounts({
      startTime,
      endTime,
      areaId: this.root
    })

    if (data.code === 0) {
      this.totals = data.data
    }
  }

  async fetchStatistics() {
    this.loading = true
    this.store.dateRange = this.filterData.dateRange

    const { data } = await this.$api.base.getPassageStatistics(
      Object.assign({ span: this.store.span, areaIds: this.defaultAreaIds }, this.params)
    )

    this.loading = false

    if (data.code === 0) {
      this.statistics = data.data
      this.store.data = data.data

      if (data.data.length > 0) {
        this.renderCharts()
      } else {
        this.disposeCharts()
      }
    }
  }

  async fetchTags() {
    await this.$store.dispatch(actionTypes.FETCH_TAGS)

    this.store.tagList = this.tags
    this.setFilteredTagList(this.tags)
  }

  formatTags(tagList: any) {
    const allTags = tagList
    const result: any[] = []

    for (const item of allTags) {
      const idx = result.findIndex(ele => ele.value === item.type)
      if (idx !== -1) {
        result[idx].children.push({
          label: item.name,
          value: item.id
        })
      } else {
        result.push({
          label: identityMap[item.type],
          value: item.type,
          children: []
        })
      }
    }
    result.push({
      label: identityMap['strange'],
      value: 'strange'
    })

    return result
  }

  async fetchAreas() {
    await this.$store.dispatch(actionTypes.FETCH_BASE_AREAS)

    this.store.areaList = this.areas
  }

  setFilteredTagList(filteredTagList: any[]) {
    const selectedTagList = filteredTagList.slice(0, 10)

    this.filteredTagList = filteredTagList
    this.selectedTagIds = selectedTagList.map(({ id }: AnyObj) => id)
    this.store.selectedTagList = selectedTagList
  }

  getToday() {
    return this.$moment().startOf('day')
  }

  getTotal(date: Moment) {
    const getTotal = (date: Moment) => {
      const result = this.totals.find(item => date.isSame(item.date))

      return result ? result.total : 0
    }

    const total = getTotal(date)
    const compared = getTotal(this.$moment(date).subtract(1, 'week'))
    const ratio = Math.abs(compared ? round(((total - compared) / compared) * 100, 2) : 0)
    const trend = compared ? (total - compared >= 0 ? 'increase' : 'decrease') : 'none'

    return { total, ratio, trend }
  }

  getTotalInRange(date: Moment, amount: number) {
    const getTotal = (date: Moment, amount: number) => {
      let total = 0

      for (let i = 0; i < amount; i++) {
        const result = this.totals.find(item =>
          this.$moment(date).subtract(i, 'day').isSame(item.date)
        )

        if (result) total += result.total
      }

      return total
    }

    const total = getTotal(date, amount)
    const compared = getTotal(this.$moment(date).subtract(amount, 'day'), amount)
    const ratio = Math.abs(compared ? round(((total - compared) / compared) * 100, 2) : 0)
    const trend = compared ? (total - compared >= 0 ? 'increase' : 'decrease') : 'none'

    return { total, ratio, trend }
  }

  handleFilterChange() {
    const { identity } = this.filterData

    if (identity) {
      const { tagIds, tagTypes } = identity
      const filteredTagList = this.tags.filter(
        ({ id, type }: AnyObj) =>
          (tagIds && tagIds.includes(id)) || (tagTypes && tagTypes.includes(type))
      )

      this.setFilteredTagList(filteredTagList)
    } else {
      this.setFilteredTagList(this.tags)
    }

    this.fetchStatistics()
  }

  handlTagsChange(tagIds: string[]) {
    this.store.selectedTagList = this.tags.filter(({ id }) => tagIds.includes(id))
    charts.line.tags(this.lineChart)
  }

  renderCharts() {
    this.$nextTick(() => {
      charts.line[this.tabValue](this.lineChart)
      charts.sunburst(this.sunburstChart)
      charts.pie(this.pieChart)
    })
  }

  resizeCharts() {
    charts.resize(this.lineChart)
    charts.resize(this.sunburstChart)
    charts.resize(this.pieChart)
  }

  disposeCharts() {
    charts.dispose(this.lineChart)
    charts.dispose(this.sunburstChart)
    charts.dispose(this.pieChart)
  }
}
